import type { ja } from './lang-ja';
import { LangComEn } from '@/locales/lang-com-en';
export const en: typeof ja = {
  com: LangComEn,
  seo: {
    title: 'INSPIRE LLC > Home',
    description: `[INSPIRE LLC] Professional Guild of the Automotive Distribution Industry`,
    keywords: `INSPIRE LLC,cars,Automobile,export, trade ,Used Car,vehicle,tokyo,Japan,Professional Guild,DAO,application development,`,
  },
  menu: {
    about: 'About us',
    contact: 'Contact us',
  },
  note: {
    row1: 'INSPIRE LLC is building a DAO to take advantage of various Japanese automotive-related technologies that we are proud to offer to the global market.',
    row2: 'We believe that an essential part of sustainable business is to focus not on materials and products, but on relationships of trust with suppliers and customers.',
    row3: 'We promote people-based business building, aiming for prosperous coexistence among those who are trustworthy and respectful of each other.',
  },
  address1: '3-11-7 Higashi-tateishi Katsushika-ku',
  address2: 'Tokyo 124-0013 JAPAN',
};
