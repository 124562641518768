<script setup lang="ts">
import * as $gtag from 'vue-gtag';
// [ stores ]
import { useAppStore } from '@/stores/app-store';
// ---------------------------------------------------
// [ store - lang ]
import { useLangStore } from '@/stores/lang-store';
import { ja } from '@/locales/home/lang-ja';
import { en } from '@/locales/home/lang-en';
// [lib]
import { GaFunc } from '@/lib/lib-front';
// -----------------------------------------------------
// [ store - lang ]
const langStore = useLangStore();
langStore.setMessage(en, ja);
const t = langStore.t;
// -----------------------------------------------------
// [ Nuxt ]
const config = useRuntimeConfig();
const router = useRouter();
const route = useRoute();
// [ stores ]
const appStore = useAppStore();
// -----------------------------------------------------
definePageMeta({
  middleware: 'redirect',
  layout: 'custom-no-header',
});
useHead({
  meta: [{ name: 'keywords', content: () => t('seo.keywords') }],
});
// プロパティ詳細は以下URL参照すること
// https://github.com/harlan-zw/zhead/blob/main/src/metaFlat.ts
useSeoMeta({
  title: () => t('seo.title'),
  ogTitle: () => t('seo.title'),
  description: () => t('seo.description'),
  ogDescription: () => t('seo.description'),
  ogImage: config.public.url + 'assets/img/OGP.png',
  twitterCard: 'summary_large_image',
});
// -----------------------------------------------------
const fixedGroupStype = computed(() => {
  return {
    height: appStore.windowFixedH,
  };
});

const clickHomeToContact = () => {
  $gtag.event(GaFunc.navigate, {
    from: route.path,
    to: `/${langStore.state.lang}/contact-us`,
    name: 'clickHomeToContact',
  });
  router.push(`/${langStore.state.lang}/contact-us`);
};

const clickHomeToAbout = () => {
  $gtag.event(GaFunc.navigate, {
    from: route.path,
    to: `/${langStore.state.lang}/about-us`,
    name: 'clickHomeToAbout',
  });
  router.push(`/${langStore.state.lang}/about-us`);
};
// -----------------------------------------------------
</script>
<template>
  <div class="page-conteiner" :style="fixedGroupStype">
    <div class="container">
      <div class="logo">
        <img src="/assets/img/inspire-llc-logo-white.svg" alt="" width="170" height="35" />
      </div>
      <!--  -->
      <div class="link-btn-group">
        <div class="link-btn">
          <div class="text" @click="clickHomeToAbout()">
            {{ t('menu.about') }}
          </div>
          <div class="cover"></div>
        </div>
        <div class="link-btn">
          <div class="text" @click="clickHomeToContact()">
            {{ t('menu.contact') }}
          </div>
          <div class="cover"></div>
        </div>
      </div>
      <!--  -->
      <div class="message">
        <div class="">
          {{ t('note.row1') }}
        </div>
        <div class="">
          {{ t('note.row2') }}
        </div>
        <div class="">
          {{ t('note.row3') }}
        </div>
      </div>
    </div>
    <div class="footer f-logo-light">
      <div class="address">
        <div class="label">{{ t('address1') }}</div>
        <div class="label">{{ t('address2') }}</div>
      </div>
      <div class="label">COPYRIGHT ©2023 INSPIRE LLC.</div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.page-conteiner {
  position: fixed;
  inset: 0;
  background-color: #000000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    inset: 0 50% 0 0;
    inset: 30% 0 0 0;
    background-image: url('/assets/img/bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
      position: fixed;
      inset: 0 0 0 0;
      background-size: cover;
    }
  }
}

.container {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
    width: 300px;
  }

  @media screen and (min-width: #{ 400 }px) and (max-width: #{ 800 - 0.1}px) {
    width: 400px;
  }

  @media screen and (min-width: #{ 800 }px) and (max-width: #{ 1200 - 0.1}px) {
    width: 750px;
  }

  @media screen and (min-width: #{ 1200 }px) {
    width: 1100px;
  }
}

.logo {
  margin: 20px 0 0px;
  width: 300px;
  user-select: none;
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
    margin: 30px 0 0px;
    width: 300px;
  }

  @media screen and (min-width: #{ 400 }px) and (max-width: #{ 800 - 0.1}px) {
    width: 380px;
  }

  @media screen and (min-width: #{ 800 }px) {
    width: 500px;
  }

  > img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.link-btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: space-around;
  justify-content: center;
  font-family: 'Yantramanav', sans-serif;
  width: 100%;
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    margin: 20px 0 0 0;
  }

  @media screen and (min-width: #{ 600 }px) {
    margin: 10px 0 0 0;
  }
}

.link-btn {
  width: calc(50% - 5px);
  max-width: 200px;
  height: 50px;
  border-top: solid 1px white;
  border-bottom: solid 1px white;
  cursor: pointer;
  overflow: hidden;
  &:first-child {
    margin: 0 10px 0 0;
  }
  &:last-child {
    margin: 0 0 0 10px;
  }

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
    height: 40px;
  }

  > div {
    position: absolute;
    inset: 0 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background-color: #ffffff1e;
    font-size: 20px;
    @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
      font-size: 18px;
    }

    &.text {
      color: rgb(255, 255, 255);
      mix-blend-mode: difference;
      z-index: 1;
    }
    &.cover {
      background-color: rgb(219, 214, 238);
      transform: translateY(calc(-100% + -1px));
      transition: transform 200ms;
      z-index: 0;
    }
  }
  &:active .cover,
  &:hover .cover {
    transform: translateY(0%);
  }
}

.message {
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
    margin: 30px 0 0 0;
    width: 300px;
  }

  @media screen and (min-width: #{ 400 }px) and (max-width: #{ 800 - 0.1}px) {
    width: 380px;
    margin: 30px 0 0 0;
  }

  @media screen and (min-width: #{ 800 }px) {
    width: 700px;
    margin: 40px 0 0 0;
  }

  text-align: center;
  // padding: 1em;
  background-color: rgba(0, 0, 0, 0.589);
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
    font-size: 13px;
  }
  @media screen and (min-width: #{ 400 }px) and (max-width: #{ 1200 - 0.1}px) {
    font-size: 14px;
  }

  @media screen and (min-width: #{ 1200 }px) and (max-width: #{ 1600 - 0.1}px) {
    font-size: 16px;
  }
  @media screen and (min-width: #{ 1600 }px) {
    font-size: 16px;
  }
}

.footer {
  position: fixed;
  inset: auto 0 0 0;
  padding: 0 4px 2px;
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.662);

  line-height: 1em;
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    flex-direction: column;
    align-items: center;
  }
  > .address {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .label {
    // font-size: 12px;
    line-height: 1.3em;
    @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
      font-size: 12px;
    }
    @media screen and (min-width: #{ 400 }px) and (max-width: #{ 1200 - 0.1}px) {
      font-size: 14px;
    }
    @media screen and (min-width: #{ 1200 }px) {
      font-size: 16px;
    }
  }
}
</style>
