//
import { LangComJa } from '@/locales/lang-com-ja';
export const ja = {
  com: LangComJa,
  seo: {
    title: 'INSPIRE LLC Webサイト:Home',
    description: `[INSPIRE LLC] 自動車流通業界のプロフェッショナルギルドWebサイト`,
    keywords: `INSPIRE LLC,インスパイア,自動車,輸出,貿易,中古車,東京,プロフェッショナルギルド,DAO,システム,エンジニア`,
  },
  menu: {
    about: 'About us',
    contact: 'Contact us',
  },
  note: {
    row1: 'INSPIRE LLCは、世界に誇る様々な日本の自動車関連の技術を活かすべくDAOを構築し、グローバルマーケットに新しい自動車ビジネスを提案して参ります。',
    row2: '持続可能な取引に必要不可欠な考えとして、ビジネスの中心を素材や商品ではなく、取引先や顧客との信頼関係とすることが重要だと我々は考えます。',
    row3: '私達は、信頼でき尊重し合える者同士での繁栄共存を目指し、人ベースのビジネス構築を推進しております。',
  },
  address1: '〒124-0013 東京都葛飾区',
  address2: '東立石3丁目11番7号',
};
